import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { isEmpty } from '@/utils/baseOperations'
import moment from "moment";

export default {
  namespaced: true,
  state: {
    Income: {},
    OnHand: {},
    Despatch: {},
    Supplier: {},
    baseCurrencyId: null,
    salesForecastData: [],
    weatherForecastData: [],
    refrigeratorList: [],
    today: moment(new Date()).format('YYYY-MM-DD'),
    nextWeek: moment().add(1, 'week').add(1, 'days').format('YYYY-MM-DD'),
    lastWeek: moment().add(-1, 'week').format('YYYY-MM-DD'),
    lastMonth: moment().add(-31, 'days').format('YYYY-MM-DD'),
    nextMonth: moment().add(31, 'days').format('YYYY-MM-DD')
  },
  getters: {
    isEmpty: state => isEmpty(state.Income),
    Income: state => state.Income,
    OnHand: state => state.OnHand,
    Despatch: state => state.Despatch,
    Supplier: state => state.Supplier,
    baseCurrencyId: state => state.baseCurrencyId,
    WeatherForecastData: state => state.weatherForecastData,
    SalesForecastData: state => state.salesForecastData,
    Today: state => state.today,
    NextWeek: state => state.nextWeek,
    LastWeek: state => state.lastWeek,
    LastMonth: state => state.lastMonth,
    NextMonth: state => state.nextMonth,
    RefrigeratorList: state => state.refrigeratorList
  },
  actions: {
    async getData({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'Dashboard Get Data',
        methodName: 'Dashboard/getData',
        method: () => {
          return HTTP.post('Dashboard', payload)
        },
        success: result => {
          commit('UPDATE_DASHBOARD_DATA', result.data)
          return result
        }
      })
      return results
    },
    async getForecastData({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'getForecastData',
        methodName: 'getForecastData',
        method: () => {
          return HTTP.post('Report/salesforecast', payload)
        },
        success: result => {
          commit('UPDATE_FORECAST_DATA', result.data)
          return result
        }
      })
      return results
    },
    async getRefrigeratorData({ commit, dispatch }, payload) {
      const results = await requestHandler({
        title: 'getRefrigeratorData',
        methodName: 'getRefrigeratorData',
        method: () => {
          return HTTP.post('Report/refrigerator', payload)
        },
        success: result => {
          commit('UPDATE_REFRIGERATOR_DATA', result.data)
          return result
        }
      })
      return results
    },
    async getRefrigeratorList({ commit, dispatch }, params) {
      const results = await requestHandler({
        title: 'getRegrigeratorList',
        methodName: 'getRegrigeratorList',
        method: () => {
          return HTTP.get('Integration/refrigerator/config/all', {...params})
        },
        success: result => {
          commit('UPDATE_REFRIGERATOR_DATA', result.data)
          return result
        }
      })
      return results
    },
  },
  mutations: {
    RESET(state) {
      state.Income = {}
      state.OnHand = {}
      state.Despatch = {}
      state.Supplier = {}
    },
    UPDATE_DASHBOARD_DATA(state, data) {
      state.Income = {
        todayTotalIncome: data.todayTotalIncome,
        thisMonthTotalIncome: data.thisMonthTotalIncome
      }
      state.OnHand = data.totalStockAmountList
      state.Despatch = data.totalTrade
      state.Supplier = data.totalSupplierTradeList
    },
    UPDATE_FORECAST_DATA(state, data) {
      state.baseCurrencyId = data.baseCurrencyId
      state.salesForecastData = data.salesForecastReportResults
      state.weatherForecastData = data.weatherResults
    },
    UPDATE_REFRIGERATOR_DATA(state, data) {
      state.refrigeratorList = data.intRefrigeratorList
    }
  }
}
